'use client'

let currDataLayerName: string | undefined = 'dataLayer'
export type GTMParams = {
  gtmId: string
  dataLayer?: string[]
  dataLayerName?: string
  auth?: string
  preview?: string
}

export const sendGTMEvent = (data: Object) => {
  if (currDataLayerName === undefined) {
    console.warn('@custom/GTM: GTM has not been initialized')
    return
  }

  // @ts-ignore
  if (window[currDataLayerName]) {
    // @ts-ignore
    window[currDataLayerName].push(data)
  } else {
    console.warn(
      `@next/third-parties: GTM dataLayer ${currDataLayerName} does not exist`,
    )
  }
}
