import { CheckoutPayloadResponse } from '../../types/checkout/responses/checkout-payload-response'
import Bugsnag from '@bugsnag/js'
import { sendGTMEvent } from '../../lib/customGTM'

export function useLogGAPurchaseEvent() {
  function logGAPurchaseEvent(
    checkoutResponse?: CheckoutPayloadResponse,
    total?: number,
    email?: string,
    isNewUser: boolean = false,
    userId?: number,
  ) {
    if (process.env.NODE_ENV !== 'production') return
    if (checkoutResponse?.transaction && (total ?? 0) > 0) {
      const transaction_id = checkoutResponse?.transaction?.id?.toString()
      const product_id = checkoutResponse?.product?.id?.toString() ?? ''
      const product_name = checkoutResponse?.product?.title?.toString() ?? ''
      const product_quantity =
        checkoutResponse?.request?.orderItem?.quantity ?? 1
      const product_total =
        checkoutResponse?.request?.orderItem?.totalUnitAmount ?? total ?? 0
      const product_price = product_total / product_quantity
      const description =
        checkoutResponse?.request?.orderItem?.product?.about ?? ''
      const externalId = userId?.toString() ?? ''
      const customer_email = email ?? ''

      // FB: content_ids、content_name、content_type、contents、currency*、num_items、value*
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
      try {
        sendGTMEvent({
          new_customer: isNewUser,
          event: 'CompletePayment',
          currency: 'USD',
          transaction_id: transaction_id,
          value: total ?? 0,
          customer_email: customer_email,
          content_ids: [product_id],
          content_name: product_name,
          content_type: 'product',
          contents: [
            {
              id: product_id,
              quantity: product_quantity,
              item_price: product_price,
            },
          ],
          quantity: product_quantity,
          description: description,
          content_id: product_id,
          email: customer_email,
          externalId: externalId,
          num_items: product_quantity,
          ecommerce: {
            currency: 'USD',
            transaction_id: transaction_id,
            value: total ?? 0,
            customer_email: customer_email,
            items: [
              {
                item_id: product_id,
                item_name: product_name,
                item_category: 'product',
                price: product_price,
              },
            ],
          },
        })

        if (isNewUser) {
          sendGTMEvent({
            new_customer: isNewUser,
            event: 'Unique Purchase',
            currency: 'USD',
            transaction_id: transaction_id,
            value: total ?? 0,
            customer_email: customer_email,
            content_ids: [product_id],
            content_name: product_name,
            content_type: 'product',
            contents: [
              {
                id: product_id,
                quantity: product_quantity,
                item_price: product_price,
              },
            ],
            quantity: product_quantity,
            description: description,
            content_id: product_id,
            email: customer_email,
            externalId: externalId,
            num_items: product_quantity,
            ecommerce: {
              currency: 'USD',
              transaction_id: transaction_id,
              value: total ?? 0,
              customer_email: customer_email,
              items: [
                {
                  item_id: product_id,
                  item_name: product_name,
                  item_category: 'product',
                  price: product_price,
                },
              ],
            },
          })
        }
      } catch (e) {
        try {
          //@ts-ignore
          Bugsnag.notify(e)
        } catch (e) {}
      }
    }
  }

  return { logGAPurchaseEvent }
}
